<template>
  <CommonButton
    v-if="!emailSend"
    bg="bg-blue"
    text="text-white"
    border="border-none"
    @click="sendActivationEmail"
  >
    {{ $t('ResendActivationMail') }}
  </CommonButton>
  <div v-else class="h-9 leading-9">
    {{ $t('EmailSendt') }}
  </div>
</template>

<script setup lang="ts">
const { sendConfirmationEmailJwt } = useEmailFlow()

const userStore = useUserStore()

const emailSend = ref(false)

async function sendActivationEmail() {
  if (!userStore.user) {
    return
  }

  const requestUrl = useRequestURL()

  const { err } = await sendConfirmationEmailJwt(
    requestUrl.href,
    userStore.user.email
  )

  if (err) {
    console.error('Error in sendConfirmationEmailJwt:', err.cause)
  } else {
    emailSend.value = true
  }
}
</script>
